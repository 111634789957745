<script setup lang="ts">
const { t } = useT();
const localPath = useLocalePath();

const { durationLeft } = useScratchCard();
</script>

<template>
	<NuxtLink :to="localPath('/scratch-cards/')" class="scratch-sidebar">
		<div class="content">
			<NuxtIcon name="235/scratch-logo" class="scratch-logo" filled />
			<AText variant="ternopil" class="text-cannes">
				{{ t("Promotion ends in") }} <b>{{ durationLeft }}</b>
			</AText>
		</div>
		<NuxtImg
			src="/nuxt-img/scratch-cards/sidebar-img.png"
			class="scratch-img"
			width="144"
			height="224"
			format="webp"
			loading="lazy"
			alt="scratch-cards-sidebar"
		/>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.scratch-sidebar {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	height: 56px;
	background: var(--gorgan);
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	padding: 12px 8px;

	.content {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	.scratch-logo {
		font-size: 98px;
		display: inline-flex;

		&:deep(svg) {
			height: auto;
			margin: 0;
		}
	}

	.scratch-img {
		position: absolute;
		right: 0;
		top: 0;
		width: 36px;
		height: auto;
		object-fit: cover;
	}
}
</style>
